export const brandNameFromCode = (code: string): string => {
    let name = "?";
    switch (code)
    {
        case "alp": name = "Alpros"; break;
        case "alr": name = "Aluar"; break;
        case "alc": name = "Alcemar"; break;
        case "fla": name = "Flamia"; break;
        case "sap": name = "Hydro"; break;
        case "mdt": name = "MDT"; break;
        case "ros": name = "Rossi"; break;
        case "alw": name = "Aluwind"; break;
        case "vrt": name = "Veratec"; break;
        case "gen": name = "Genérica"; break;
        case "ala": name = "Alar"; break;
        case "sod": name = "Sodal"; break;
        case "arq": name = "Arquetipo"; break;
        case "alm": name = "Alumet"; break;
        case "wnh": name = "Winhouse"; break;
    }
    return name;
}

export const flagFromCountryCode = (code: string): string => {
    let flag = "";
    switch (code)
    {
        case "CHL": flag = "🇨🇱"; break;
        case "BOL": flag = "🇧🇴"; break;
        case "PER": flag = "🇵🇪"; break;
        case "MEX": flag = "🇲🇽"; break;
    }
    return flag;
}
    // { code: "ARG", flag: "🇦🇷" },