import {
  Button,
  Spinner,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { BrandList } from "../../types/types";
import { useState } from "react";

type PlanSelectorProps = {
  value: string;
  options: { code: string; name: string }[];
  loading: boolean;
  onChange: (value: string) => void;
};

type BrandSelectorProps = {
  value: string[];
  options: { code: string; name: string }[];
  loading: boolean;
  onChange: (value: string[]) => void;
};

type PlanChangeBoxProps = {
  currentPlan: string;
  currentBrands: string[];
  plans: { code: string; name: string }[];
  brands: BrandList[];
  loading: boolean;
  onConfirm: (plan: string, brands: string[]) => void;
};

const PlanSelector = (props: PlanSelectorProps) => {
  return (
    <ToggleButtonGroup
      className="d-flex flex-wrap"
      type="radio"
      name="options"
      value={props.value}
      onChange={(value) => props.onChange(value)}
    >
      {props.options.map((op) => (
        <ToggleButton
          id={`radio-plan-${op.code}`}
          key={op.code}
          value={op.code}
          type="radio"
          variant="outline-dark"
          disabled={props.loading}
        >
          {op.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const BrandSelector = (props: BrandSelectorProps) => {
  return (
    <ToggleButtonGroup
      className="d-flex flex-wrap gap-1"
      type="checkbox"
      size="sm"
      value={props.value}
      onChange={(value) => props.onChange(value)}
    >
      {props.options.map((op) => (
        <ToggleButton
          id={`check-brand-${op.code}`}
          key={op.code}
          value={op.code}
          variant="outline-success"
          disabled={props.loading}
        >
          {op.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const PlanChangeBox = (props: PlanChangeBoxProps) => {
  const [selectedPlan, setSelectedPlan] = useState<string>(props.currentPlan);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(
    props.currentBrands
  );
  const [showBrands, setShowBrands] = useState<boolean>(
    props.currentPlan !== "basc"
  );

  const planChangeHandler = (value: string) => {
    setSelectedPlan(value);
    setShowBrands(value !== "basc");
    if (value === "basc") setSelectedBrands(["gen"]);
    else setSelectedBrands([]);
  };

  const confirmHandler = () => {
    props.onConfirm(selectedPlan, selectedBrands);
  };

  return (
    <Stack className="bg-light border border-dark p-3 gap-3">
      <span>Seleccione el nuevo plan y/o marcas</span>
      <Stack className="mx-3 gap-3">
        <PlanSelector
          options={props.plans}
          value={selectedPlan}
          loading={props.loading}
          onChange={planChangeHandler}
        />
        {showBrands && (
          <BrandSelector
            options={props.brands}
            value={selectedBrands}
            loading={props.loading}
            onChange={(value) => setSelectedBrands(value)}
          />
        )}
      </Stack>
      <Button
        className="d-flex flex-row gap-2 align-items-center justify-content-center"
        onClick={confirmHandler}
        disabled={
          props.loading ||
          selectedBrands.length === 0 ||
          selectedBrands.length > 2
        }
      >
        {props.loading && <Spinner animation="border" size="sm" />}
        <span>{props.loading ? "Procesando..." : "Confirmar"}</span>
      </Button>
    </Stack>
  );
};

export default PlanChangeBox;
