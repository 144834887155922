import styles from "./MainHeader.module.css";

type MainHeaderProps = {
  loggedIn: boolean;
  onLogout: () => void;
};

const MainHeader = (props: MainHeaderProps) => {

  const logoutButtonHandler = () => {
    props.onLogout();
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <span className={styles.title}>AluDig Admin</span>
          {props.loggedIn && (
            <button
              type="button"
              className="btn btn-outline-light text-nowrap"
              onClick={logoutButtonHandler}
            >
              Cerrar sesión
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default MainHeader;
