export enum PaymentTerm {
  MONTH = "m",
  QUARTER = "q",
  YEAR = "y",
}

export const PLANS: {
  code: string;
  name: string;
  brandLimit: number;
  intl: boolean;
  advFeatures: boolean;
  fixedBrands?: string[];
}[] = [
  {
    code: "basc",
    name: "Básico",
    brandLimit: 1,
    intl: false,
    fixedBrands: ["gen"],
    advFeatures: false,
  },
  {
    code: "stan",
    name: "Standard",
    brandLimit: 1,
    intl: true,
    advFeatures: true,
  },
  {
    code: "prem",
    name: "Premium",
    brandLimit: 2,
    intl: true,
    advFeatures: true,
  },
];

export type User = {
  id: string;
  name: string;
  code: string;
  brands: string;
  plan: string;
  joinDate: string;
  active: boolean;
  init: boolean;
  processing: boolean;
  paid: boolean;
  country: string;
  intl: boolean;
  email?: string;
  payTerm?: string;
};

export type UserDetail = {
  id: string;
  name: string;
  phone: string;
  email: string;
  city: string;
  brands: string[];
  plan: string;
  planName: string;
  join: string;
  activity: string;
  country: string;
};

export type UserInitData = {
  firstTime: boolean;
  data?: {
    name: string;
    phone: string;
    email: string;
    city: string;
    brands: string;
    plan: string;
  };
};

export type UsersFetchResponse = {
  users: User[];
  pageCount: number;
  userCount: number;
};

export type AuthResponse = {
  token: string;
  expireDate: Date;
};

export type BrandList = {
  code: string;
  name: string;
  country: string;
};
