import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <div className="m-5">
        <p>Página no encontrada.</p>
        <Link to="/">Volver</Link>
      </div>
    </>
  );
};

export default NotFoundPage;
