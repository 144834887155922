import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UsersTable from "../components/UsersTable/UsersTable";

type PanelPageProps = {
  token: string;
};

const PanelPage = (props: PanelPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.token) {
      navigate("/");
    }
  }, [props.token, navigate]);

  return (
    <>
      <UsersTable token={props.token} />
    </>
  );
};

export default PanelPage;
