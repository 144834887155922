import { useState } from "react";
import { Button, Collapse, Modal, Stack } from "react-bootstrap";
import { BrandList, PLANS, UserDetail } from "../../types/types";
import { formatDateString } from "../../utils/dateUtils";
import { brandNameFromCode, flagFromCountryCode } from "../../globalConstants";
import PlanChangeBox from "./PlanChangeBox";

type UserModalProps = {
  show: boolean;
  data?: UserDetail;
  brands: BrandList[];
  processing: boolean;
  onPlanChange: (id: string, plan: string, brands: string[]) => void;
  onClose: () => void;
};

const UserModal = (props: UserModalProps) => {
  const [showPlanChangeBox, setShowPlanChangeBox] = useState(false);

  const planChangeHandler = (plan: string, brands: string[]) => {
    if (!props.data) return;
    props.onPlanChange(props.data.id, plan, brands);
  };

  const closeHandler = () => {
    props.onClose();
    setShowPlanChangeBox(false);
  };

  return (
    <Modal show={props.show} centered>
      <Modal.Header>Detalle de Usuario</Modal.Header>
      <Modal.Body>
        {props.data ? (
          <>
            <Stack className="mb-3">
              <span>{props.data.name}</span>
              <span>{props.data.phone}</span>
              <span>{props.data.email}</span>
              <span>
                {props.data.city} ({props.data.country}){" "}
                {flagFromCountryCode(props.data.country)}
              </span>
              <span>
                {props.data.brands.map((b) => brandNameFromCode(b)).join(", ")}
              </span>
              <span>{props.data.planName}</span>
            </Stack>
            <Stack>
              <span>{`Ingreso: ${formatDateString(props.data.join)}`}</span>
              <span>{`Última actividad: ${formatDateString(
                props.data.activity
              )}`}</span>
            </Stack>

            <Button
              className="mt-3"
              size="sm"
              variant="info"
              disabled={props.processing}
              onClick={() => setShowPlanChangeBox(!showPlanChangeBox)}
              aria-controls="plan-change-box"
              aria-expanded={showPlanChangeBox}
            >
              Cambio de plan/marcas
            </Button>
            <Collapse className="mt-2" in={showPlanChangeBox}>
              <div id="plan-change-box">
                <PlanChangeBox
                  plans={PLANS.filter(
                    (p) =>
                      p.intl === true ||
                      p.intl === (props.data?.country !== "ARG")
                  )}
                  brands={props.brands.filter(
                    (b) => !b.country || b.country === props.data?.country
                  )}
                  currentPlan={props.data.plan}
                  currentBrands={props.data.brands}
                  loading={props.processing}
                  onConfirm={planChangeHandler}
                />
              </div>
            </Collapse>
          </>
        ) : (
          <p>Cargando...</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
