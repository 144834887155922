import "./App.css";
import MainHeader from "./components/MainHeader/MainHeader";
import PanelPage from "./pages/PanelPage";
import AuthPage from "./pages/AuthPage";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { AuthResponse } from "./types/types";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  const [token, setToken] = useState<string>("");
  const timerRef = useRef(-1);

  const navigate = useNavigate();

  const loginHandler = (data: AuthResponse) => {
    setToken(data.token);
    navigate("/panel");

    const expirationTime =
      new Date(data.expireDate).getTime() - new Date().getTime();
    const timerId = setTimeout(() => {
      logoutHandler();
    }, expirationTime);
    timerRef.current = Number(timerId);
  };

  const logoutHandler = () => {
    setToken("");
    navigate("/");
    clearTimeout(timerRef.current);
  };

  return (
    <>
      <MainHeader loggedIn={Boolean(token)} onLogout={logoutHandler} />
      <main style={{ marginTop: "85px" }}>
        <Routes>
          <Route path="/" element={<AuthPage onLogin={loginHandler} />} />
          <Route path="panel" element={<PanelPage token={token} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
