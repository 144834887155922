export const formatDate = (date: Date) => {
  return date.toLocaleDateString("es-AR", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatDateString = (date: string) => {
  return formatDate(new Date(date));
};
