import React, { useState } from "react";
import { useMutation } from "react-query";
import { login, twofaLogin } from "../services/services";
import { AuthResponse } from "../types/types";
import { IMaskInput } from "react-imask";
import { Button } from "react-bootstrap";

type AuthPageProps = {
  onLogin: (response: AuthResponse) => void;
};

const AuthPage = (props: AuthPageProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [displayTwofa, setDisplayTwofa] = useState(false);
  const [twofaToken, setTwofaToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const loginMutation = useMutation(() => login(username, password), {
    onSuccess: (data) => {
      setTempToken(data.token);
      setDisplayTwofa(true);
    },
    onError: () => {
      setError("Credenciales inválidas.");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const twofaMutation = useMutation(() => twofaLogin(tempToken, twofaToken), {
    onSuccess: (data) => {
      props.onLogin(data);
    },
    onError: () => {
      setError("Código inválido.");
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const loginFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    loginMutation.mutate();
  };

  const twofaFormHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    twofaMutation.mutate();
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        {error && (
          <div className="alert alert-danger">
            <p className="mx-3">{error}</p>
          </div>
        )}
        {!displayTwofa ? (
          <form className="d-flex flex-column">
            <div className="mb-3">
              <label htmlFor="inputUser" className="form-label mb-0">
                Usuario
              </label>
              <input
                id="inputUser"
                name="username"
                type="text"
                className="form-control"
                autoComplete="false"
                minLength={3}
                maxLength={24}
                value={username}
                disabled={loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUsername(e.target.value)
                }
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPass" className="form-label mb-0">
                Contraseña
              </label>
              <input
                id="inputPass"
                name="password"
                type="password"
                className="form-control"
                autoComplete="false"
                minLength={3}
                maxLength={24}
                value={password}
                disabled={loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                required
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              className="mt-1"
              disabled={!username || !password || loading}
              onClick={loginFormHandler}
            >
              Ingresar
            </Button>
          </form>
        ) : (
          <form className="d-flex flex-column">
            <div className="mb-3">
              <label htmlFor="inputUser" className="form-label mb-0">
                Código temporal
              </label>
              <IMaskInput
                className="form-control text-center"
                id="inputToken"
                name="token"
                mask={"000000"}
                value={twofaToken}
                disabled={loading}
                onChange={() => {}}
                onAccept={(value: string) => setTwofaToken(value)}
                required
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              className="mt-1"
              disabled={!twofaToken}
              onClick={twofaFormHandler}
            >
              Confirmar
            </Button>
          </form>
        )}
        {loading && (
          <div className="mt-4 spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AuthPage;
